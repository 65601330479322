/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect, useRef } from 'react';
import intlTelInput from 'intl-tel-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import * as statuses from '../../../../services/store/states';

import './iti.css';
import styles from './InputPhone.module.css';
import stylesInput from '../shared/Input.module.css';
import 'intl-tel-input/build/css/intlTelInput.css';

export default function InputPhone({
  phone, setPhone, required, label, state,
}) {
  const [value, setValue] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [phoneIti, setPhoneIti] = useState(null);
  const phoneRef = useRef();

  useEffect(() => {
    if (phoneRef.current) {
      const iti = intlTelInput(phoneRef.current, {
        initialCountry: 'fr',
        autoPlaceholder: 'aggressive',
        formatOnDisplay: true,
        separateDialCode: true,
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.6/js/utils.js',
      });
      setPhoneIti(iti);
    }
  }, []);

  useEffect(() => {
    if (state === statuses.FAIL) {
      setIsValidPhone(false);
    }
  }, [state]);

  useEffect(() => {
    if (value === '') {
      setValue(phone);
    }
  }, [phone, value]);

  return (
    <div>
      <p
        className={stylesInput.label}
      >
        {(required) && (<span className={styles.required}> * </span>)}
        { label }
      </p>
      <div className={`${stylesInput.input} ${styles.inputPhoneContainer}`}>
        <input
          ref={phoneRef}
          className={styles.phoneInput}
          type="tel"
          id="phone"
          name="phone"
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            setPhone(phoneIti.getNumber(), e);
            setIsValidPhone(true);
          }}
          onBlur={() => {
            if (phoneIti !== null) {
              if (phoneIti.isValidNumber()) {
                setIsValidPhone(true);
              } else {
                setIsValidPhone(false);
              }
            }
          }}
        />
      </div>
      {
        (!isValidPhone)
        && (
        <p className={`${stylesInput.hint} ${styles.error}`}>
          <FontAwesomeIcon className={styles.iconError} icon={faTimes} />
          Le numéro de téléphone est invalide
        </p>
        )
}
    </div>
  );
}
