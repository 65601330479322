import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import {
  faDownload, faEye, faSort, faStar, faTrash,
} from '@fortawesome/free-solid-svg-icons';

import Button from '../../library/Button/Button';
import styles from './DocumentsTable.module.css';

const DocumentsTableRow = ({
  document, isFavorite, handleChange, handleDelete, selectDocument,
  isAuthorized, actions,
}) => {
  const [isStarHovered, setIsStarHovered] = useState(false);

  const { t } = useTranslation();

  const handleFavorite = () => {
    handleChange(!isFavorite);
  };

  const handleDeleteConfirmation = () => {
    const deleteAndClose = () => {
      handleDelete();
      actions.closePopUp();
    };
    actions.openPopUp(
      t('documents.delete.confirmation'),
      '',
      <div className={styles.rowConfirmation}>
        <Button
          label={t('cancel')}
          variant="ghost"
          onButtonClick={actions.closePopUp}
        />
        <Button
          label={t('validate')}
          onButtonClick={deleteAndClose}
        />
      </div>,
    );
  };

  return (
    <>
      <li className={`${styles.rowItem} ${(!isAuthorized) ? styles.row : styles.row4}`}>
        <p className={styles.value}>
          {document.type || document.title}
        </p>
        {
          (isAuthorized)
          && (
            (document.shareholder)
              ? (
                <p>
                  {`${document.shareholder.firstName} ${document.shareholder.lastName} (${document.shareholder.email})`}
                </p>
              )
              : (
                <p />
              )
          )
        }
        <p className={styles.date}>
          {document.date}
        </p>
        <div className={styles.toolbar}>
          <button
            type="button"
            onMouseEnter={() => setIsStarHovered(true)}
            onMouseLeave={() => setIsStarHovered(false)}
            onClick={handleFavorite}
          >
            {
              (isStarHovered)
                ? (
                  <FontAwesomeIcon
                    icon={(isFavorite) ? faStarEmpty : faStar}
                    className={(isFavorite) ? '' : styles.star}
                  />
                )
                : (
                  <FontAwesomeIcon
                    icon={(isFavorite) ? faStar : faStarEmpty}
                    className={(isFavorite) ? styles.star : ''}
                  />
                )
            }
          </button>
          <a
            className={styles.download}
            href={document.url}
            download={document.title}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faDownload} />
          </a>
          <button
            type="button"
            className={styles.preview}
            onClick={() => selectDocument({ url: document.url, title: document.title })}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          {
            (isAuthorized)
            && (
              <button
                type="button"
                className={styles.preview}
                onClick={handleDeleteConfirmation}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )
          }
        </div>
      </li>
    </>
  );
};

export default function DocumentsTable({
  documents, favorites, updateDocumentById, deleteDocumentById,
  sort, selectDocument, roles, isAuthorized, actions, isRelatedDocument,
}) {
  const { t } = useTranslation();
  return (
    <section className={styles.table}>
      <h2>
        {t('documents.list')}
      </h2>
      <ul className={styles.list}>
        <li className={(!isAuthorized) ? styles.row : styles.row4}>
          <div className={styles.cellTitle}>
            <p>
              {t('name')}
            </p>
            <button
              type="button"
              onClick={() => sort('title')}
            >
              <FontAwesomeIcon icon={faSort} />
            </button>
          </div>
          {
            (isAuthorized)
            && (
              <div className={styles.cellTitle}>
                <p>
                  {t('assembly.summons.editor.shareholders')}
                </p>
                <button
                  type="button"
                  onClick={() => sort('first_name')}
                >
                  <FontAwesomeIcon icon={faSort} />
                </button>
              </div>
            )
          }
          <div className={`${styles.cellTitle} ${styles.date}`}>
            <p>
              Date
            </p>
            <button
              type="button"
              onClick={() => sort('date')}
            >
              <FontAwesomeIcon icon={faSort} />
            </button>
          </div>
          <div />
        </li>
        {
          (!documents || documents.length === 0)
          && (
            <>
              <li className={`${styles.item} ${styles.skeleton}`} />
              <li className={`${styles.item} ${styles.skeleton}`} />
            </>
          )
        }
        {
            documents.map((document) => (
              <DocumentsTableRow
                key={document.id}
                document={document}
                isFavorite={favorites.includes(document.id)}
                handleChange={(value) => updateDocumentById(document.id, value)}
                handleDelete={() => deleteDocumentById(document.id)}
                selectDocument={selectDocument}
                roles={roles}
                isAuthorized={isAuthorized}
                actions={actions}
                isRelatedDocument={isRelatedDocument}
              />
            ))
          }
      </ul>
    </section>
  );
}
