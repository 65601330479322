import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faDownload, faEdit } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'components/library/Button/Button';
import ImgPlaceholder from 'components/ImgPlaceholder';
import useOutsideClickAlert from 'components/library/hook/clickOutside';

import ShareholdersForm from '../ShareholdersForm';
import styles from './ShareholdersDescription.module.css';

function ShareholdersDescription({
  isPopUpOpen, contact, actions, history,
}) {
  const descriptionRef = useRef(null);
  const { t } = useTranslation();
  const { society: id } = useParams();
  const handleOutsideClick = (isOpen) => {
    if (!isOpen) {
      actions.setActiveContact(null);
    }
  };
  useOutsideClickAlert(descriptionRef, () => handleOutsideClick(isPopUpOpen), isPopUpOpen);
  if (contact && contact !== null) {
    return (
      <section className={styles.shareholders}>
        <div
          className={`slideInRight ${styles.description}`}
          ref={descriptionRef}
        >
          <div className={styles.wrapper}>
            <div className={styles.rowSpaced}>
              <div className={styles.row}>
                <ImgPlaceholder
                  className={styles.profilePicture}
                  src={contact.img}
                  alt={`${contact.firstName} ${contact.lastName}`}
                />
                <div
                  className={styles.profileInfo}
                >
                  <p className={styles.profileName}>
                    {`${contact.firstName} ${contact.lastName}`}
                  </p>
                  <p className={styles.profileMail}>
                    { contact.mail }
                  </p>
                  <p className={styles.profilePhone}>
                    { contact.phone }
                  </p>
                </div>
              </div>
              <button
                type="button"
                className={styles.edition}
                onClick={() => {
                  actions.openPopUp(
                    t('shareholders.edit.title'),
                    t('shareholders.edit.description'),
                    (<ShareholdersForm contact={contact} closePopUp={actions.closePopUp} />),
                  );
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                type="button"
                className={styles.close}
                onClick={() => actions.setActiveContact(null)}
              >
                <CloseIcon />
              </button>
            </div>
            <p
              className={styles.title}
            >
              {t('shareholders.edit.personalInfo')}
            </p>
            <div
              className={styles.grid}
            >
              <p>
                {t('shareholders.edit.address')}
              </p>
              <p>
                { `${contact?.address?.address || ''}, ${contact?.address?.zipCode || ''} ${contact?.address?.city || ''}` }
              </p>
              <p>
                {t('shareholders.edit.birthday')}
              </p>
              <p>
                { contact.birthday }
              </p>
            </div>
            <p
              className={styles.title}
            >
              {t('shareholders.edit.invest')}
            </p>
            <div
              className={styles.grid}
            >
              <p>
                {t('shareholders.edit.share')}
              </p>
              <p>
                { contact.shares }
              </p>
              <p>
                {t('shareholders.edit.capital')}
              </p>
              <p>
                { contact.capital }
              </p>
            </div>
            {
          (contact.documents && contact.documents.length > 0)
          && (
            <div
              className={styles.documents}
            >
              <p
                className={styles.title}
              >
                Documents
              </p>
              <ul className={styles.list}>
                {
                  contact.documents.map((document) => (
                    <li key={document.id}>
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        className={styles.documentIcon}
                      />
                      <p>
                        { document.title }
                      </p>
                      <a
                        href={document.download}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </a>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
          }
          </div>
          <Button
            label={t('shareholders.edit.send')}
            type="secondary"
            onButtonClick={() => {
              actions.setActiveRoom(contact.id);
              history.push(`/${id}/chat`);
            }}
            disabled
          />
        </div>
        <div className={`fadeIn ${styles.overlay}`} />
      </section>
    );
  }
  return null;
}

export default withRouter(ShareholdersDescription);
