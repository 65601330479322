import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import ShareholdersFormRespondent from 'components/Shareholders/ShareholdersForm/ShareholdersFormRespondent';
import OperationsShareholdersForm from 'components/OperationsShareholdersForm';
import OperationsShareholdersAsk from 'components/Operation/OperationsShareholdersAsk';
import OperationsShareholderUpdate from 'components/Operation/OperationsShareholderUpdate';

import styles from './OperationsShareholders.module.css';

const FormState = ({
  state, setState, back, callback, isHolding,
}) => {
  const FORM_STATES = {
    choice: <ShareholdersFormRespondent setStep={setState} isHolding={isHolding} />,
    create: <OperationsShareholdersForm back={back} callback={callback} isHolding={isHolding} />,
    ask: <OperationsShareholdersAsk back={back} callback={callback} isHolding={isHolding} />,
    update: <OperationsShareholderUpdate back={back} callback={callback} isHolding={isHolding} />,
  };
  return (
    <div>{FORM_STATES[state]}</div>
  );
};

export default function OperationsShareholders({
  back, callback, actions, isHolding = false,
}) {
  const [status, setStatus] = useState('choice');
  const { t } = useTranslation();

  const handleBack = () => {
    if (status === 'choice') back();
    else setStatus('choice');
  };

  useEffect(() => () => {
    actions.resetCreation();
  }, []);

  return (
    <section className={styles.creation}>
      <header>
        <button
          type="button"
          onClick={handleBack}
          className={styles.back}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
          />
        </button>
        <h3>
          {t('operation.shareholderChoice')}
        </h3>
      </header>
      <FormState
        state={status}
        setState={setStatus}
        back={back}
        callback={callback}
        isHolding={isHolding}
      />
    </section>
  );
}
