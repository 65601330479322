/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link, useParams } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import Button from '../../../components/library/Button/Button';
import InputText from '../../../components/library/Input/InputText/InputText';
import InputDate from '../../../components/library/Input/InputDate/InputDate';
import useForm from '../../../hook/useForm';
import * as statuses from '../../../services/store/states';

import AssemblyResolution from '../AssemblyResolution/AssemblyResolution';
import AssemblyHeader from '../AssemblyHeader/AssemblyHeader';
import shared from '../shared/assembly.module.css';

import AssemblySummonsInvitation from './AssemblySummonsInvitation';
import validate from './validate';
import styles from './AssemblySummons.module.css';

export default function AssemblySummonsForm({
  status, assembly, shareholders, actions, next, societyId, roles,
  isAuthorized,
}) {
  const [mailsList, setMailsList] = useState(assembly?.invitations || []);
  const [isInvitationPopUpOpen, setIsInvitationPopUpOpen] = useState(false);
  const [isResolutionPopUpOpen, setIsResolutionPopUpOpen] = useState(false);
  const [resolutions, setResolutions] = useState(
    assembly?.resolutions?.map((r, key) => ({ order: key, ...r, tmpId: r.id })) || [],
  );
  const [selectedResolution, setSelectedResolution] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { t } = useTranslation();
  const { id: assemblyId } = useParams();

  const handleOnDragEnd = (result) => {
    const items = Array.from(resolutions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newItems = items.map((i, index) => ({ ...i, order: index + 1 }));
    setResolutions(newItems);
  };

  const submit = (values) => {
    setHasSubmitted(true);
    const newAssembly = {
      ...values,
      resolutions,
      invitations: (mailsList) ? mailsList.map((mail) => ({
        email: mail.email,
        shareholderId: mail.shareholderId,
      })) : [],
    };
    actions.updateAssemblyPlanById(newAssembly.id, newAssembly);
    next();
  };

  useEffect(() => {
    if (Object.keys(selectedResolution).length > 0) {
      setIsResolutionPopUpOpen(true);
    }
  }, [selectedResolution]);

  useEffect(() => {
    if (hasSubmitted && status === 'success') {
      next();
    }
  }, [status]);

  const {
    values, errors, handleChange, handleSubmit,
  } = useForm(submit, (data) => validate(data, t), assembly);

  return (
    <section className={styles.summons}>
      {
        (isInvitationPopUpOpen)
        && (
          <AssemblySummonsInvitation
            shareholders={shareholders}
            invitations={mailsList}
            close={() => setIsInvitationPopUpOpen(false)}
            callback={(mails) => {
              setMailsList(mails);
            }}
          />
        )
      }
      {
        (isResolutionPopUpOpen)
        && (
          <AssemblyResolution
            close={() => {
              setIsResolutionPopUpOpen(false);
              setSelectedResolution({});
            }}
            resolution={selectedResolution}
            submit={(newResolution) => {
              if (Object.keys(selectedResolution).length > 0) {
                setResolutions((state) => state.map((currResolution) => (
                  (currResolution.tmpId === selectedResolution.tmpId)
                    ? { ...newResolution, tmpId: currResolution.tmpId }
                    : currResolution
                )));
              } else if (resolutions && resolutions.length > 0) {
                const tmpId = Math.max(...resolutions.map((i) => Number.parseInt(i.tmpId, 10))) + 1;
                setResolutions((state) => (
                  [...state, { ...newResolution, tmpId, order: tmpId + 1 }]
                ));
              } else {
                setResolutions((state) => (
                  [...state, { ...newResolution, tmpId: 0, order: 1 }]
                ));
              }
              setIsResolutionPopUpOpen(false);
            }}
          />
        )
      }
      <AssemblyHeader
        roles={roles}
        societyId={societyId}
        isAuthorized={isAuthorized}
      />
      <section className={shared.section}>
        <h2 className={styles.title}>
          { assembly?.title }
        </h2>
        <div className={styles.content}>
          <header className={styles.spacedRow}>
            <h3 className={styles.subTitle}>
              {t('assembly.summons.title')}
            </h3>
          </header>
          <main className={styles.main}>
            <div className={styles.description}>
              <h4>
                {t('assembly.summons.details.title')}
              </h4>
              <p>
                {t('assembly.summons.details.description')}
              </p>
            </div>
            <form>
              <InputText
                type="text"
                name="title"
                label={t('assembly.summons.name')}
                valueInput={values?.title || ''}
                onTextChange={handleChange}
                state={errors?.title && statuses.FAIL}
                hint={errors?.title}
              />
              <div className={styles.row}>
                <InputDate
                  label={t('assembly.summons.date')}
                  className={styles.inputDate}
                  name="date"
                  day={values?.date?.day || ''}
                  month={values?.date?.month || ''}
                  year={values?.date?.year || ''}
                  onChange={(date, e) => {
                    e.target.name = 'date';
                    e.target.value = '';
                    handleChange(e, date);
                  }}
                  includeDay
                  state={errors?.date && statuses.FAIL}
                  hint={errors?.date}
                />
                <div
                  className={styles.time}
                >
                  <p className={styles.label}>
                    {t('assembly.summons.time')}
                  </p>
                  <input
                    type="time"
                    name="time"
                    className={`${styles.timeInput} ${(errors.time) ? styles.error : ''}`}
                    value={values?.time || ''}
                    onChange={handleChange}
                  />
                  {
                      (errors?.time)
                      && (
                        <p
                          className={`${styles.hint} ${styles.error}`}
                        >
                          {errors.time}
                        </p>
                      )
                    }
                </div>
              </div>
              <InputText
                label={t('assembly.summons.place')}
                type="text"
                name="place"
                valueInput={values?.place || ''}
                onTextChange={handleChange}
                state={errors?.place && statuses.FAIL}
                hint={errors?.place}
              />
              <p className={styles.label}>
                Participants
              </p>
              <Button
                label={t('assembly.summons.invites')}
                className={styles.inviteButton}
                onButtonClick={() => setIsInvitationPopUpOpen(true)}
              />
              {
                (mailsList && mailsList.length > 0)
                  ? (
                    <p className={styles.inviteHint}>
                      <span>
                        <CheckCircleOutlineIcon />
                      </span>
                      {t('assembly.summons.guest.selected', { guest: mailsList.length })}
                    </p>
                  )
                  : (
                    <p className={styles.inviteHintError}>
                      <span>
                        <CancelOutlinedIcon />
                      </span>
                      {t('assembly.summons.guest.empty')}
                    </p>
                  )
              }
              <p className={styles.label}>
                Résolutions
              </p>
              {
                  (resolutions && (resolutions.length > 0))
                    ? (
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="resolutions">
                          {
                            (provided) => (
                              <ul
                                className={shared.list}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {
                            resolutions.map((resolution, index) => (
                              <Draggable
                                key={resolution.tmpId}
                                draggableId={`${resolution.tmpId}`}
                                index={index}
                              >
                                {
                                  (providedItem) => (
                                    <div
                                      className={styles.resolutionGroup}
                                      ref={providedItem.innerRef}
                                      {...providedItem.draggableProps}
                                      {...providedItem.dragHandleProps}
                                    >
                                      <div
                                        className={styles.resolution}
                                      >
                                        <p className={styles.resolutionText}>
                                          <span>
                                            {t('assembly.resolutions.description', { index: index + 1, title: resolution?.title })}
                                          </span>
                                          { resolution.description }
                                        </p>
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setSelectedResolution(resolution);
                                        }}
                                      >
                                        <EditIcon />
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          if (resolution.id) {
                                            actions
                                              .deleteAssemblyResolution(resolution.id, assemblyId);
                                          }
                                          setResolutions((state) => (
                                            state.filter((item) => (
                                              item.tmpId !== resolution.tmpId
                                            ))
                                          ));
                                        }}
                                      >
                                        <ClearIcon />
                                      </button>
                                    </div>
                                  )
                                }
                              </Draggable>
                            ))
                          }
                                {
                                provided.placeholder
                              }
                              </ul>
                            )
                          }
                        </Droppable>
                      </DragDropContext>
                    )
                    : (
                      <p>
                        { t('assembly.resolutions.empty') }
                      </p>
                    )
                }
              <Button
                label={t('assembly.resolutions.add')}
                onButtonClick={() => {
                  setIsResolutionPopUpOpen(true);
                }}
              />
            </form>
          </main>
          <div className={styles.submit}>
            <Link
              className={styles.outline}
              to={`/${societyId}/assemblee/view/${assemblyId}`}
            >
              {t('assembly.summons.back')}
            </Link>
            <Button
              label={t('assembly.summons.create')}
              onButtonClick={handleSubmit}
              disabled={
                (!mailsList)
                || (!resolutions)
                || (mailsList && mailsList.length === 0)
                || (resolutions && resolutions.length === 0)
              }
            />
          </div>
        </div>
      </section>
    </section>
  );
}
