import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '../library/Button/Button';
import styles from './Header.module.css';

export default function Header({
  title, navigation, searchbar, button, back = [], backFunction,
}) {
  return (
    <header
      className={styles.header}
    >
      <div
        className={styles.headerRow}
      >
        <div className={styles.headerRow}>
          {
            (back && back !== '')
            && (
              <Link
                to="/documents"
              >
                <FontAwesomeIcon
                  className={styles.headerBack}
                  icon={faArrowLeft}
                />
              </Link>
            )
          }
          {
            (backFunction)
            && (
              <button
                type="button"
                className={styles.headerBack}
                onClick={() => backFunction()}
              >
                <FontAwesomeIcon
                  className={styles.headerBack}
                  icon={faArrowLeft}
                />
              </button>
            )
          }
          <h1 className={styles.title}>
            { title }
          </h1>
        </div>
        {
          (navigation) && <div className={styles.separator} />
        }
        {
          (navigation && Array.isArray(navigation)) && (
            navigation.map((link) => {
              if (link.roles && link.roles.length > 0) {
                return null;
              }
              return (
                <NavLink
                  key={link.label}
                  to={link.url}
                  className={`${styles.link} ${link.className}`}
                  activeClassName={styles.active}
                  exact={link.exact}
                  onClick={link.onClick}
                >
                  {link.label}
                </NavLink>
              );
            })
          )
        }
      </div>
      {
        (!navigation)
        && (
        <div
          className={styles.buttonGroup}
        >
          { searchbar }
          {
              (button)
              && (
              <Button
                type="secondary"
                label={button.label}
                onButtonClick={button.action}
                className={button.style}
              />
              )
            }
        </div>
        )
      }
    </header>
  );
}

Header.defaultProps = {
  title: '',
  searchbar: null,
  button: null,
};

Header.propTypes = {
  title: PropTypes.string,
  searchbar: PropTypes.element,
  button: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    style: PropTypes.string,
  }),
};
