import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';

import ImgPlaceholder from '../../ImgPlaceholder';
import fetchMedia from '../../../utils/getFile';
import * as statuses from '../../../services/store/states';

import styles from './DocumentsVisualisation.module.css';
import Loading from '../../library/Loading';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function DocumentsVisualisation({ selectedDocument, actions }) {
  const [status, setStatus] = useState(statuses.LOADING);
  const [file, setFile] = useState();
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const imgArray = ['jpg', 'jpeg', 'JPG', 'JPEG', 'png', 'PNG'];
  const { t } = useTranslation();

  const shouldFetch = () => {
    if (selectedDocument) {
      const regex = new RegExp(/media\.ayomi\.fr\/media/);
      return regex.test(selectedDocument.url);
    }
    return false;
  };

  useEffect(() => {
    let cancel = false;
    document.body.style.overflow = 'hidden';
    const handleSetUrl = (data) => {
      if (!cancel) {
        setFile(data);
        setStatus('SUCCESS');
      }
    };
    if (!shouldFetch() && selectedDocument) {
      setFile(selectedDocument.url);
      setStatus('SUCCESS');
    } else if (!cancel && selectedDocument) {
      fetchMedia(handleSetUrl, null, selectedDocument.url);
    }
    return () => {
      document.body.style.overflow = 'unset';
      cancel = true;
    };
  }, [selectedDocument]);

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
    setStatus('SUCCESS');
  }

  if (!selectedDocument) return null;
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <div className={styles.titleContainer}>
          <button
            type="button"
            className={styles.link}
            onClick={() => {
              actions.setSelectedDocument(null);
              actions.setSelectedUserDocument(null);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h1>
            { selectedDocument?.title }
          </h1>
        </div>
        <a
          className={styles.link}
          href={file}
          download={selectedDocument?.title}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faDownload} />
        </a>
      </header>
      {
        (selectedDocument.url?.split('.').pop() === 'pdf')
        && (
          <div>
            {
              status === 'LOADING'
              && <Loading />
            }
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              options={{ workerSrc: 'pdf.worker.js' }}
              className={styles.document}
            >
              <Page pageNumber={pageNumber} className={styles.page} />
            </Document>
            {
              (totalPages > 1)
              && (
                <div className={styles.pagination}>
                  <p className={styles.number}>
                    {t('documents.visualisation.pagination', { active: pageNumber, total: totalPages })}
                  </p>
                  <div>
                    <button
                      type="button"
                      onClick={() => setPageNumber((state) => state - 1)}
                      className={(pageNumber === 1) ? styles.disabled : null}
                      disabled={(pageNumber === 1)}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <button
                      type="button"
                      onClick={() => setPageNumber((state) => state + 1)}
                      className={(pageNumber === totalPages) ? styles.disabled : null}
                      disabled={(pageNumber === totalPages)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </div>
              )
            }
          </div>
        )
      }
      {
        (imgArray.includes(selectedDocument.url?.split('.').pop()))
        && (
          <div className={styles.imageContainer}>
            <ImgPlaceholder src={selectedDocument.url} alt="Document sélectionné" />
          </div>
        )
      }
      {
        ((!(selectedDocument.url?.split('.').pop() === 'pdf')) && (!imgArray.includes(selectedDocument.url?.split('.').pop())))
        && (
          <div className={styles.preview}>
            <p>
              {t('documents.visualisation.preview')}
            </p>
          </div>
        )
      }
    </section>
  );
}
