import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import styles from './ChatRoom.module.css';
import { SocketContext } from '../../../services/socket/socket';
import * as events from '../../../services/socket/eventType';

export default function ChatRoom({
  isDashboard = false, room, id, actions,
}) {
  const socket = useContext(SocketContext);

  const [newMessage, setNewMessage] = useState('');

  const messageRef = useRef(null);

  useEffect(() => {
    socket.on(events.PRIVATE_MESSAGE, ({ content, from }) => {
      actions.receiveMessage({ content, from });
    });
    socket.on(events.GROUP_MESSAGE, ({ content, from }) => {
      actions.receiveGroupMessage({ content, from });
    });
  }, []);

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
    }
  }, [room]);

  const handleSendMessage = () => {
    if (id === 'group') {
      actions.sendGroupMessage(socket, newMessage, room.id);
      setNewMessage('');
    } else {
      actions.sendMessage(socket, newMessage, room.socketId, room.id);
      setNewMessage('');
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  if (!room) return null;
  return (
    <div className={(isDashboard) ? styles.chatRoomDashboard : styles.chatRoom}>
      <div className={styles.header}>
        <button
          type="button"
          className={styles.backButton}
          onClick={() => actions.setActiveRoom(null)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        {
          (room.img)
            ? (
              <img
                className={styles.roomPicture}
                src={room.img}
                alt={room.name}
              />
            )
            : (
              <div className={styles.roomLogo}>
                {room?.name?.substring(0, 1)}
              </div>
            )
        }
        <div className={styles.roomInfo}>
          <p className={styles.subtitle}>{room.name}</p>
          <p className={styles.description}>{room.description}</p>
        </div>
      </div>
      <div className={styles.chat}>
        <div ref={messageRef} className={styles.messageOverflow}>
          <div className={styles.conversationContainer}>
            {
              room.messages.map((message) => (
                <div
                  className={`${styles.messageContainer} ${(message?.ownedByCurrentUser) ? styles.send : styles.receive}`}
                  key={message?.id}
                >
                  {message?.user?.name && (
                  <p className={styles.name}>
                    {' '}
                    { message.user.name }
                  </p>
                  )}
                  <p
                    className={`${styles.message} ${(message?.ownedByCurrentUser) ? styles.send : styles.receive}`}
                  >
                    { message?.content }
                  </p>
                </div>
              ))
            }
          </div>
        </div>
        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            value={newMessage}
            placeholder="Message"
            type="text"
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleEnter}
          />
          <button
            className={styles.button}
            type="button"
            onClick={handleSendMessage}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </div>
    </div>
  );
}
