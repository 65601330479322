import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faCheck } from '@fortawesome/free-solid-svg-icons';

import useOutsideClickAlert from '../library/hook/clickOutside';

import styles from './TableDropdown.module.css';

export default function TableDropdown({ categories, categoriesSelected, handleClick }) {
  const [isDropdownOpen, setIsDropdown] = useState(false);
  const dropdownRef = useRef();
  useOutsideClickAlert(dropdownRef, () => setIsDropdown(false));
  return (
    <div ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsDropdown((state) => !state)}
      >
        <FontAwesomeIcon className={styles.icon} icon={faFilter} />
      </button>
      {
        (isDropdownOpen && categories)
        && (
        <ul
          className={styles.filterDropdown}
        >
          {
            categories.map((category) => (
              <div
                className={styles.filter}
                key={category}
              >
                {
                  (categoriesSelected.includes(category))
                    ? (
                      <button
                        type="button"
                        aria-label={`Selectionner ${category}`}
                        className={`${styles.categorySelector} ${styles.selected}`}
                        onClick={() => {
                          handleClick(category);
                        }}
                      >
                        <FontAwesomeIcon className={styles.icon} icon={faCheck} />
                      </button>
                    )
                    : (
                      <button
                        type="button"
                        className={`${styles.categorySelector}`}
                        aria-label={`Déselectionner ${category}`}
                        onClick={() => {
                          handleClick(category);
                        }}
                      />
                    )
                }
                <p>{ (category) || 'Autres' }</p>
              </div>
            ))
          }
        </ul>
        )
      }
    </div>
  );
}
